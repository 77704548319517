<header class="header">
    <div class="head-nav">
        <div class="hd hd--left">
            <div class="menu-icon" (click)="togglePopup()" #menuIcon>
                <img alt="menu" class="menu" src="./assets/images/menu.png"/>
            </div>
            <div class="logo">
                <img alt="MyLogo" class="myPrxlogo" src="./assets/images/MyLogo.svg"/>
            </div> 
        </div>
        <div class="hd hd--right">
            <!--<a (click)="openSPC()" class="ntf-icon"><svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.28564 26.1484V8.14844C2.28564 7.59844 2.48148 7.1276 2.87314 6.73594C3.26481 6.34427 3.73564 6.14844 4.28564 6.14844H20.2856C20.8356 6.14844 21.3065 6.34427 21.6981 6.73594C22.0898 7.1276 22.2856 7.59844 22.2856 8.14844V20.1484C22.2856 20.6984 22.0898 21.1693 21.6981 21.5609C21.3065 21.9526 20.8356 22.1484 20.2856 22.1484H6.28564L2.28564 26.1484Z" fill="#7A27C9"/>
                <rect x="11.7856" y="0.648438" width="19" height="13" rx="6.5" fill="#FE0181"/>
                <rect x="11.7856" y="0.648438" width="19" height="13" rx="6.5" stroke="white"/>
                <path d="M21.2945 11.2479C20.8068 11.2479 20.3712 11.1638 19.9877 10.9957C19.6065 10.8277 19.3047 10.5945 19.0822 10.2962C18.862 9.9955 18.7436 9.64749 18.7271 9.25213H19.8421C19.8563 9.46757 19.9285 9.65459 20.0587 9.81321C20.1913 9.96946 20.3641 10.0902 20.5772 10.1754C20.7903 10.2607 21.027 10.3033 21.2874 10.3033C21.5739 10.3033 21.8272 10.2536 22.0474 10.1541C22.2699 10.0547 22.4439 9.91619 22.5694 9.73864C22.6949 9.55871 22.7576 9.35156 22.7576 9.11719C22.7576 8.87334 22.6949 8.65909 22.5694 8.47443C22.4463 8.28741 22.2652 8.14062 22.0261 8.03409C21.7893 7.92756 21.5029 7.87429 21.1667 7.87429H20.5523V6.9794H21.1667C21.4366 6.9794 21.6733 6.93087 21.8769 6.83381C22.0829 6.73674 22.2439 6.6018 22.3599 6.42898C22.4759 6.25379 22.5339 6.04901 22.5339 5.81463C22.5339 5.58973 22.483 5.39441 22.3812 5.22869C22.2817 5.06061 22.1397 4.92921 21.955 4.83452C21.7727 4.73982 21.5573 4.69247 21.3087 4.69247C21.072 4.69247 20.8506 4.73627 20.6447 4.82386C20.4411 4.90909 20.2753 5.0322 20.1475 5.19318C20.0197 5.3518 19.951 5.54238 19.9415 5.76491H18.8797C18.8916 5.37192 19.0076 5.02628 19.2278 4.72798C19.4503 4.42969 19.7439 4.1965 20.1084 4.02841C20.473 3.86032 20.8779 3.77628 21.3229 3.77628C21.7893 3.77628 22.1918 3.86742 22.5303 4.04972C22.8712 4.22964 23.134 4.46993 23.3187 4.7706C23.5057 5.07126 23.598 5.40033 23.5957 5.75781C23.598 6.16501 23.4844 6.51065 23.2547 6.79474C23.0275 7.07884 22.7244 7.26941 22.3457 7.36648V7.4233C22.8286 7.49669 23.2027 7.68845 23.4678 7.99858C23.7353 8.30871 23.8679 8.69342 23.8655 9.1527C23.8679 9.55279 23.7566 9.91146 23.5317 10.2287C23.3092 10.5459 23.005 10.7957 22.6191 10.978C22.2332 11.1579 21.7917 11.2479 21.2945 11.2479Z" fill="white"/>
                </svg>                
                </a>-->
            <a><img alt="MyLogo" class="bell-icon" src="./assets/images/bell.png "/></a>
            <a (click)="showSection('profile')"><img alt="bell" class="bell-icon" src="/assets/images/user-profile.png"/></a>
        </div>
    </div>
</header> 
<div class="backdrop" #backdrop></div>
<div id="popup-menu" class="popup-menu space-y-4" #popupMenu #breadcrumbs>
    <a (click)="showSection('dashboard')">
        <span class="material-symbols-outlined">home</span>
        <span>Home</span>
    </a>
    <a (click)="showSection('pendingPayments')"> 
        <span class="material-symbols-outlined">credit_card</span>
        <span>Pending Payments</span>
    </a>
    <a (click)="showSection('myMeds')">
        <span class="material-symbols-outlined">history</span>
        <span>My Meds</span>
    </a>
    <a (click)="showSection('readyToDispense')">
        <span class="material-symbols-outlined">package</span>
        <span>Ready to Dispense</span>
    </a>
    <a (click)="showSection('newPrescription')">
        <span class="material-symbols-outlined">prescriptions</span>
        <span>New Prescription</span>
    </a>
    <a (click)="showSection('refillsDue')">
        <span class="material-symbols-outlined">medication</span>
        <span>Refills Due</span>
    </a>
    <a (click)="showSection('profile')">
        <span class="material-symbols-outlined">account_circle</span>
        <span>Profile</span>
    </a>
    <a href="#" (click)="logout()">
        <span class="material-symbols-outlined">logout</span>
        <span>Log out</span>
    </a>
</div>