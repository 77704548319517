import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { QuantityPipe } from '../pipes/quantity.pipe';
import { DateTimeUtcPipePipe } from '../pipes/date-time-utc-pipe.pipe';
import { MaskTextPipe } from '../pipes/mask-text.pipe';
import { PharmNumberformatPipe } from '../pipes/pharm-numberformat.pipe';
import { ErrorPopupComponent } from '../myPrimeRx/components/error-popup/error-popup.component';
import { HeaderComponent } from '../myPrimeRx/components/header/header.component';
import { FooterComponent } from '../myPrimeRx/components/footer/footer.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    QuantityPipe,
    DateTimeUtcPipePipe,
    MaskTextPipe,
    PharmNumberformatPipe,
    ErrorPopupComponent    
  ],
  imports: [
    CommonModule
  ],
  exports:[
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    QuantityPipe,
    DateTimeUtcPipePipe,
    MaskTextPipe,
    PharmNumberformatPipe,
    ErrorPopupComponent
  ]
})
export class SharedModule { }
