import { Component, ElementRef, ViewChild, ViewEncapsulation, AfterViewInit, HostListener, OnInit, NgZone  } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { StateService } from '../../services/history.state.service';
import { CommmonUtil } from '../utils/common.util';

@Component({
  selector: 'my-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit { 

  @ViewChild('menuIcon', { static: true }) menuIcon!: ElementRef;   
  @ViewChild('popupMenu', { static: true }) popupMenu!: ElementRef;
  @ViewChild('backdrop', { static: true }) backdrop!: ElementRef;
  @ViewChild('breadcrumbs', { static: true }) breadcrumbs!: ElementRef;
  activeSection: string = '';
  private needScroll = false;
  
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private stateService: StateService,
    private commmonUtil:CommmonUtil,
    private ngZone: NgZone) {
      this.stateService.activeSection$.subscribe(section => {
        this.activeSection = section;
        this.needScroll = true;
      });
    } 

  ngOnInit() {
  }

  showSection(section: string) {
      if(section === 'dashboard'){
        this._router.navigate(['/dashboard']);
      } else if(section === 'profile'){
        this._router.navigate(['/profile']);
      } else {
        this.stateService.setActiveSection(section); 
        this._router.navigate(['/PendingPayments']);
      }
      this.closeFilter(); 
  }
  togglePopup() { 
    const popupMenu = this.popupMenu.nativeElement; 
    const backdrop = this.backdrop.nativeElement; 
    const computedStyles = window.getComputedStyle(popupMenu);
    const display = computedStyles.getPropertyValue('display'); 

    if (display === 'none') {
      const icon = this.menuIcon.nativeElement;
      const position = icon.getBoundingClientRect();
      const top = position.bottom + window.scrollY;
      const left = position.left - 15;  

      popupMenu.style.display = 'block';
      popupMenu.style.top = `${top}px`;
      popupMenu.style.left = `${left}px`;
      backdrop.style.display = 'block';
    } else { 
      popupMenu.style.display = 'none';
      backdrop.style.display = 'none';
    }
  }

  ngAfterViewInit() {
    document.addEventListener('click', (event) => this.onClick(event));
    this.needScroll = true;
  }

  ngAfterViewChecked(): void {
    if (this.needScroll) {
      this.scrollActiveButtonIntoView();
      this.needScroll = false; 
    }
  }
  scrollActiveButtonIntoView(): void {
    if (this.breadcrumbs) {
      this.commmonUtil.scrollActiveButtonIntoView(this.breadcrumbs, this.activeSection);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) { 
    if (this.menuIcon?.nativeElement && this.popupMenu?.nativeElement) {
      const icon = this.menuIcon.nativeElement;
      const position = icon.getBoundingClientRect();
      const top = position.bottom + window.scrollY;
      const left = position.left - 15;  

      const popupMenu = this.popupMenu.nativeElement;
      popupMenu.style.top = `${top}px`;
      popupMenu.style.left = `${left}px`;
    }
  }

  onClick(event: MouseEvent) {
    if (this.popupMenu?.nativeElement && this.menuIcon?.nativeElement) {
        const popupMenu = this.popupMenu.nativeElement;
        const backdrop = this.backdrop.nativeElement;
        const menuIcon = this.menuIcon.nativeElement; 
        if (!menuIcon.contains(event.target) && !popupMenu.contains(event.target)) {
          popupMenu.style.display = 'none';
          backdrop.style.display = 'none';
        }
    }
  }
  closeFilter() {
    if(this.popupMenu?.nativeElement){
      const popupMenu = this.popupMenu.nativeElement;
      const backdrop = this.backdrop.nativeElement;
      popupMenu.style.display = 'none';
      backdrop.style.display = 'none';
    }
  }
  openSPC(){
    this._router.navigate(['/spc']);
  }
  logout() {
    this._authService.logout().subscribe(() => {
      localStorage.clear();
      this._router.navigate(['/login']);
    });
  }
}
