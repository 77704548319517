import {environment} from './../../environments/environment';
export const constant = {
    REDIRECT_URL:"/",
    SMS:'sms',
    LOGIN_URL: "login", 
    ELINK_GET: "Elink/{id}/{guid}/{npi}",
    USER_REG_POST: "User",
    USER_LOGIN_POST: "User/Token",  
    USER_OTP: "User/OTP",
    USER_SEND_OTP: "User/SendOTP",
    USER_PATIENT_PHARMACY_INFO: 'User/PatientPharmacyInfo',
    USER_RESEND_OTP: "User/ResendOTP",  
    MOBILE_PAYMENT_POST: "MobilePayment",  
    USER_LOGOUT: "logout", 
    PASSWORD_VALID_REXP: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/,
    EMAIL_VALID_REXP: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    GET_PENDINGPAYMENTS_TRANSCATION: "Transaction/GetPendingPaymentTransactions",
    GET_PENDINGPAYMENTS_GETIMAGEURLS: "Transaction/GetImageUrls",
    Get_SETUP_TRANS:"Transaction/SetupSaleTransaction",
    GET_PATIENT_PHARMACY_INFO:"User/PatientPharmacyInfo",
    POST_PHARMACY_INFO:"User/PharmacyInfo",
    POST_USER_DETAILS:"User/Details",
    PHONE_NUMBER_TEN_DIGITS_REXP:  /^\d{10}$/,
    POST_TRANSDETAILS_INFO:"PaymentProcessor/TransactionDetail",
    /* myPrimeRx API end Points */
    POST_DASHBOARD_COUNTS: "Dashboard/DashboardCounts",
    POST_DASHBOARD_PATIENT_INFO: "Dashboard/PatientInfo",
    POST_MYMEDS_HISTORY: "Prescription/RxHistory",
    POST_READY_FOR_PICKUP: "Prescription/ReadyForPickup",
    POST_NEW_ERXS: "Prescription/NewErxs",
    POST_GET_REFILLS_DUE: "Prescription/GetRefillsDue", 
    POST_ADD_REFILLS_QUE: "Prescription/AddToRefillQueue",
    POST_USER_DETAIL_EMAIL: "User/Details/Email",
    GENERAL: 'General',
    BIRTHDAY: 'Birthday', 
    PENDING_PAYMENTS: 'pendingPayments',
    MY_MEDS: 'myMeds',
    READY_TO_DISPENSE: 'readyToDispense',
    NEW_PRESSCRIPTION: 'newPrescription',
    REFILLS_DUE: 'refillsDue',
    SUBSCRIPTION: 'Subscription',
    UNSUBSCRIBE: 'UnSubscribe',
    UPDATE_SUBSCRIPTION: 'User/UpdateSubscription',
    NO_DATA_FOUND:'No data found',
    unAuth_Admin_MSG:'Unable to authorize, please contact the pharmacy.',
    user_not_found_MSG:'User not found, please contact administrator.',
    trans_fialed_MSG:'Please try again or contact administrator.',
    trans_details_MSG:'Transaction details missing or invalid'
}